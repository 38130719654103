<template>
  <SafeForm @submit.prevent="onContinue">
    <div>
      <DatePicker
        v-model="beginningOfMembership"
        :label="$t('beginningOfMembership')"
        floating
        required
        :min="todayIso"
        @input="onBeginningInput"
      />
      <DatePicker
        v-if="configs.defaultContractBeginDay === constants.CONTRACT_BEGIN_START_OF_NEXT_MONTH"
        v-model="beginningOfTraining"
        :label="$t('beginningOfTraining')"
        floating
        required
        :min="todayIso"
        :max="beginningOfMembership || null"
        @input="getPreUse"
      />
      <div
        v-if="configs.defaultContractBeginDay === constants.CONTRACT_BEGIN_START_OF_NEXT_MONTH"
        class="flex w-full mb-2 pt-2"
      >
        <div class="w-1/2 mr-2 text-gray-800">
          {{ $t('advanceUsageFee') }}
        </div>
        <div class="flex-grow text-gray-800 mb-4">
          <div class="font-bold">
            <!-- <template v-if="feesModels.advanceUsageFee">{{ $n(fees.advanceUsageFee.default, 'currency') }}</template> -->
            <!-- <template v-else>{{ $n(fees.advanceUsageFee.min, 'currency') }}</template> -->
            {{ $n(preUse ? advanceUsageFee : 0, 'currency') }}
          </div>
        </div>
        <div v-if="preUseCanChange" class="flex-shrink-0 ml-3">
          <SwitchToggle v-model="preUse" />
        </div>
      </div>
      <!-- <DatePicker
        v-if="feesModels.advanceUsageFee || fees.advanceUsageFee.min > 0"
        v-model="dueDateForAdvanceUse"
        :label="$t('dueDateForAdvanceUse')"
        floating
      /> -->
      <!-- <DatePicker
        v-model="dueDateForAdvanceUse"
        :label="$t('dueDateForAdvanceUse')"
        floating
      /> -->
    </div>
    <div class="flex flex-shrink w-full mt-12 pb-12">
      <Button class="mr-2 w-full" :to="fees.length === 0 ? 'contracts' : 'flat-rates'">
        {{ $t('back') }}
      </Button>
      <Button class="ml-2 w-full" primary type="submit">
        {{ $t('continue') }}
      </Button>
    </div>
  </SafeForm>
</template>

<script>
import { format } from 'date-fns';
import { CONTRACT_BEGIN_TODAY, CONTRACT_BEGIN_START_OF_NEXT_MONTH } from '../../../../constants';

export default {
  data() {
    return {
      beginningOfMembership: null,
      beginningOfTraining: null,
      advanceUsageFee: null,
      dueDateForAdvanceUse: null,
      preUse: true,
    };
  },

  computed: {
    todayIso() {
      return format(new Date(), 'yyyy-MM-dd');
    },
    fees() {
      return this.$store.state.contract.fees;
    },
    preUseCanChange() {
      const { configs } = this.$store.state.contract;
      if (configs && configs.preUseDiscount) {
        return configs.preUseDiscount;
      }
      return false;
    },
    configs() {
      return this.$store.state.contract.configs;
    },
    constants() {
      return {
        CONTRACT_BEGIN_TODAY,
        CONTRACT_BEGIN_START_OF_NEXT_MONTH,
      };
    },
  },

  watch: {
    beginningOfMembership(newVal) {
      this.$store.state.contract.newContract.startingConditions.beginningOfMembership = newVal;
    },
    beginningOfTraining(newVal) {
      this.$store.state.contract.newContract.startingConditions.beginningOfTraining = newVal;
    },
    configs() {
      return this.$store.state.contract.configs;
    },
    constants() {
      return {
        CONTRACT_BEGIN_TODAY,
        CONTRACT_BEGIN_START_OF_NEXT_MONTH,
      };
    },
  },

  created() {
    const { startingConditions } = this.$store.state.contract.newContract;
    this.beginningOfMembership = startingConditions.beginningOfMembership;
    this.beginningOfTraining = startingConditions.beginningOfTraining;
    this.advanceUsageFee = startingConditions.advanceUsageFee;
    this.dueDateForAdvanceUse = startingConditions.dueDateForAdvanceUse;
    this.preUse = startingConditions.preUse;
    this.getPreUse();
  },

  methods: {
    onBeginningInput() {
      if (this.beginningOfTraining > this.beginningOfMembership) {
        this.beginningOfTraining = this.beginningOfMembership;
      }
      if (this.configs.defaultContractBeginDay === this.constants.CONTRACT_BEGIN_TODAY) {
        this.beginningOfTraining = this.beginningOfMembership;
      }
      this.getPreUse();
    },
    getPreUse() {
      this.$store.dispatch('calculatePreUse', {
        trainingStart: this.beginningOfTraining,
        contractStart: this.beginningOfMembership,
      }).then((resp) => {
        this.advanceUsageFee = resp.data.preUseAmount;
        this.dueDateForAdvanceUse = format(new Date(), 'yyyy-MM-dd');
      });
    },
    onContinue() {
      // if (this.feesModels.advanceUsageFee) {
      //   this.advanceUsageFee = this.fees.advanceUsageFee.default;
      // } else {
      //   this.advanceUsageFee = this.fees.advanceUsageFee.min;
      // }
      const {
        beginningOfMembership,
        beginningOfTraining,
        advanceUsageFee,
        dueDateForAdvanceUse,
        preUse,
      } = this;
      this.$store.commit('updateContractForm', {
        startingConditions: {
          beginningOfMembership,
          beginningOfTraining,
          advanceUsageFee,
          dueDateForAdvanceUse,
          preUse,
        },
      });
      this.$router.push('customer');
    },
  },
};
</script>
